module.exports = {
  donate: "Donaciones",
  donate_text: "Ayúdanos a ser imparables. Hazlo posible con una donación.",
  whatsapp_header: "Canal de Whatsapp",
  whatsapp_text: "Mantente al dia de toda la información de la campaña",
  whatsapp_instructions: "Escribe ALTA al 607 812 839 y guarda el número en tus contactos",
  whatsapp_cta: "Suscríbete!",
  join: "Voluntarios",
  programa: "Propuestas",
  follow: "Sigue la actividad de",
  donate_action_header: "Ayúdanos a hacer la campaña",
  donate_action_text: "Con una simple donación, ayudas a hacer a Joan Ribó alcalde",
  donate_action_cta: "Haz una donación",
  inspector_action_header: "Sé garantía de democracia",
  inspector_action_text: "El 26 de mayo, hazte interventor/a o apoderado/a de Compromís para garantizar que cada voto cuente.",
  inspector_action_cta: "Hazte interventor/a",
  news_header: "Noticias",
  news_more_on: "Más noticias en valencia.compromis.net",
  news_read_more: "Leer más",
  events_header: "Actos"
}
